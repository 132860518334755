import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ThanksView from "../views/ThanksView.vue"

const routes = [
 {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: ThanksView,
    props: true,
  },
 
];

 const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
   routes,
 });
export default router;





