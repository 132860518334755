<template>
  <div>
    <!--header-->
    <sticky-view />
    <section class="fixed w-full top-0 bg-white z-50 shadow-lg">
      <div class="bg-white shadow-lg py-4">
        <div
          class="max-w-7xl mx-auto px-4 flex gap-5 justify-between place-items-center"
        >
          <div class="flex gap-6 items-center text-center">
            <a href="/" aria-label="redirect to homepage">
              <img
                src="../assets/Vignan-logo.webp"
                class="md:w-[100%] w-[350px]"
                alt="logo"
              />
            </a>
          </div>
          <div class="flex items-center">
            <div>
              <div class="flex justify-center items-center gap-2">
                <div
                  class="bg-[#CA2526] my-4 text-white text-lg hover:text-red-500 py-2 rounded-lg shadow-lg w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] justify-center items-center"
                  data-v-920e5a82=""
                >
                  <a href="https://apply.vignanodl.com/" target="_blank" tabindex="-1"
                    ><p class="uppercase font-semibold">Apply Now</p></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--header-->
    <section>
      <div
        v-if="this.Electives"
        id="enq"
        class="ww-full mx-auto relative ban-img bg-cover bg-no-repeat"
      >
        <div class="pt-20">
          <div
            class="absolite max-w-7xl mx-auto py-8 flex flex-col lg:flex-row place-items-center"
          >
            <div
              class="md:w-[20%] p-4 w-full md:mt-3 mx-auto xl:w-auto lg:text-left text-center"
            >
              <div>
                <div
                  class="font-bold md:text-[28px] text-[18px] lg:text-left text-center"
                >
                  <h2 class="md:leading-[50px]">
                    Open & Distance Learning (ODL) <br />Programs by
                    <span class="text-[#CA2526]">Vignan</span>
                  </h2>
                </div>
                <div class="mt-3 md:w-[90%] w-full">
                  <h3
                    class="md:text-xl text-lg list5 font-semibold md:py-3 rounded-lg"
                  >
                    Elevate your career with an education that adapts to your
                    lifestyle, offering convenience and flexibility.
                  </h3>
                </div>

                <div class="lg:hidden pt-4 flex justify-center">
                  <img
                    src="../assets/odl/banner-main-img.webp"
                    class="h-full w-[70%] md:w-full"
                    alt="banner"
                  />
                </div>

                <div
                  class="flex flex-row lg:flex-col w-full text-sm md:text-[15px] pt-4 lg:text-left text-center lg:justify-start justify-center md:m-auto"
                >
                  <!-- <ul class="text-left list-disc font-semibold list-inside text-gray-600 text-lg pl-3" v-html="Electives.focus">
                </ul> -->
                  <div
                    class="text-gray-600 font-semibold float-left lg:text-left text-center leading-8"
                    v-html="Electives.focus"
                  ></div>
                </div>
              </div>
            </div>
            <div class="hidden lg:block p-5">
              <div class="w-full bottom-0">
                <img
                  src="../assets/odl/banner-main-img.webp"
                  class="h-full w-full md:block hidden"
                  alt="desktop-banner"
                />
              </div>
            </div>

            <div class="md:w-[70%] w-[90%] md:pr-5 md:mt-16">
                <div
                  class="w-[100%] border-2 border-gray-400 shadow-lg rounded-lg"
                >
                <div
          class="bg-white shadow-md p-8"
        >
          <p class="p-5">
            Thank you! Your Enquiry has been submitted successfully. Our
            admissions team will get in touch with you soon. A verification
            email has been sent to your registered email ID.<br />
            Please ignore if already verified.
          </p>
        </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w-full p-5 bg-[#f7f9fb]">
      <div class="max-w-7xl justify-center items-center m-auto">
        <div class="flex flex-wrap justify-center items-left gap-8">
          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] lg:border-r-2 lg:border-[#ccc] border-[#ccc] lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >45</span
            >
            <p class="lg:text-left text-center">years of glorious legacy</p>
          </div>

          <!-- <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] lg:border-r-2 lg:border-[#ccc] border-[#ccc] lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >3,000+</span
            >
            <p class="lg:text-left text-center">
              additional courses on SWAYAM & NPTEL
            </p>
          </div> -->

          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] lg:border-r-2 lg:border-[#ccc] border-[#ccc] lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >360°</span
            >
            <p class="lg:text-left text-center">career support</p>
          </div>

          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] l lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >3000+</span
            >
            <p class="lg:text-left text-center">learners worldwide</p>
          </div>
          <!-- <div class="border-t-2 bg-white"></div> -->
          <!-- <div class="border-t-2 w-64 border-white"></div> -->
        </div>
      </div>
    </section>

    <section>
      <div class="max-w-7xl mx-auto text-center p-4">
        <h2
          class="font-bold lg:text-2xl mt-8 uppercase text-[20px] px-8 py-4 text-gray-700 p-2 pt-4"
        >
          Online MBA Program
          <span class="text-[#D02829]">with Electives</span>
        </h2>

        <div class="flex pb-5 flex-col">
          <div class="flex flex-col lg:flex-row gap-2">
            <ul
              class="flex lg:w-[40%] mb-0 list-none lg:flex-col flex-row md:gap-3 gap-1 pt-3 pb-4 flex-wrap"
            >
              <li class="mr-2 text-left">
                <a
                  class="text-md font-bold uppercase md:px-5 px-3 text-md py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(1)"
                  v-bind:class="{
                    'text-white bg-gray-600': openTab !== 1,
                    'text-white bg-[#D02829]': openTab === 1,
                  }"
                  >Finance</a
                >
              </li>
              <li class="lg:block hidden mr-2 text-left">
                <a
                  class="text-md font-bold uppercase md:px-5 px-3 text-md py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(2)"
                  v-bind:class="{
                    'text-white bg-gray-600': openTab !== 2,
                    'text-white bg-[#D02829]': openTab === 2,
                  }"
                  >Human Resource Management</a
                >
              </li>
              <li class="lg:hidden mr-2 text-left">
                <a
                  class="text-md font-bold uppercase md:px-5 px-3 text-md py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(2)"
                  v-bind:class="{
                    'text-white bg-gray-600': openTab !== 2,
                    'text-white bg-[#D02829]': openTab === 2,
                  }"
                  title="Human Resource Management"
                  >HRM</a
                >
              </li>
              <li class="mr-2 text-left">
                <a
                  class="text-md font-bold uppercase md:px-5 px-3 text-md py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(3)"
                  v-bind:class="{
                    'text-white bg-gray-600': openTab !== 3,
                    'text-white bg-[#D02829]': openTab === 3,
                  }"
                  >Marketing</a
                >
              </li>
            </ul>
            <div class="w-[100%]">
              <div class="px-4 py-5 lg:flex">
                <div class="tab-content tab-space justify-start text-left">
                  <div
                    v-bind:class="{
                      hidden: openTab !== 1,
                      block: openTab === 1,
                    }"
                  >
                    <div class="mb-8">
                      <div class="w-full">
                        <p>
                          An Online MBA in Finance will prepare you with skills
                          in corporate financial planning, financial management,
                          banking and accounting, and more. It opens up various
                          opportunities in the corporate world, allowing you to
                          learn and apply the acquired skills in analyzing
                          company reports, forecasting business trends,
                          maximizing profitability, minimizing financial risks,
                          and managing capital investment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-bind:class="{
                      hidden: openTab !== 2,
                      block: openTab === 2,
                    }"
                  >
                    <div class="mb-8 flex break-words">
                      <div class="w-full">
                        <p>
                          An Online MBA in Human Resource Management empowers
                          you for significant contributions in the dynamic HR
                          field. Over a two-year program, experienced faculty
                          guide you through managing human capital, developing
                          effective HR strategies, and creating positive work
                          environments. The carefully designed curriculum
                          ensures practical skills relevant to real-world HR
                          scenarios, giving you a competitive edge in
                          organizational development and employee engagement.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-bind:class="{
                      hidden: openTab !== 3,
                      block: openTab === 3,
                    }"
                  >
                    <div class="mb-8 flex break-words">
                      <div class="w-full">
                        <p>
                          An Online MBA in Marketing is a dynamic two-year
                          program designed to elevate your marketing skills and
                          propel your career forward. You will delve into the
                          strategic and tactical aspects of marketing, gaining
                          comprehensive knowledge and skills from experienced
                          faculty and industry professionals. This program
                          equips you with a competitive edge to navigate
                          complexities, devise innovative strategies, seize
                          emerging opportunities, and drive business growth in
                          today's competitive market.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="bg-[#f7f9fb] pb-5" id="sec">
      <div class="w-full mx-auto max-w-7xl pt-10 flex flex-col p-5">
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            Advanced<span class="text-[#d02829]"> Certifications</span>
          </h2>

          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-col md:flex-row gap-6">
          <div class="md:w-1/2 text-left md:pt-10">
            <div
              class="flex flex-col md:flex-row justify-between items-start font-semibold text-[15px] mt-4 md:gap-5"
            >
              <div class="md:w-[70%] w-full ml-4">
                <ul class="red-dot-list text-left leading-[45px]">
                  <li class="relative">Digital Marketing &amp; E-commerce</li>
                  <li class="relative">Banking and FinTech</li>
                  <li class="relative">Healthcare and Hospital Management</li>
                  <li class="relative">Data Science &amp; Analytics</li>
                  <li class="relative">Project Management</li>
                  <li class="relative">LSCM</li>
                  <li class="relative">Operations Management</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md:w-[50%] w-[70%] justify-center align-middle pt-4">
            <img
              class="md:w-2/3 justify-center align-middle m-auto"
              src="../assets/advanced-certifications.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section> -->

    <section>
      <div class="w-full mx-auto max-w-7xl py-8">
        <div>
          <h2 class="text-center font-bold pb-6 text-lg md:text-2xl uppercase">
            Rankings & <span class="text-[#e33237]">Recognitions</span>
          </h2>
        </div>
        <div class="w-full relative justify-center m-auto">
          <div
            class="prev-rank1 absolute bottom-0 left-24 md:top-[40%] md:-left-9 z-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-prev.svg"
              width="40px"
              height="40px"
              alt="slider-previous"
            />
          </div>
          <div
            class="slick-ranking flex justify-center m-auto items-center gap-10"
          >
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p>
                    Accredited by National Assessment and Accreditation Council
                    (NAAC) with an A+ Grade
                  </p>
                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg mb-8"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr1.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-center items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    Ranked amongst the top 75th in India under National
                    Institutional Ranking Framework (NIRF), Ministry of
                    Education, Government of India
                  </p>
                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr2.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-16">
                    University Grants Commission (UGC) Entitled Online Degree
                    Programs of Vignan Online are equivalent to on-campus mode
                    of learning
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr3.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    Vignan's Foundation for Science, Technology, and Research is
                    Awarded ISO 9001:2015 certification
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr4.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    India Today 2021 - Best Universities in India Rank: 25
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr5.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="next-rank1 absolute bottom-0 right-24 md:top-[40%] md:-right-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-next.svg"
              width="40px"
              height="40px"
              alt="slider-next"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#f7f9fb] pb-5" id="sec4">
      <div class="max-w-7xl justify-center items-center m-auto pb-4">
        <div class="max-w-7xl justify-center items-center m-auto pb-4">
          <div
            class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
          >
            <h2
              class="font-bold uppercase lg:text-2xl mt-8 px-8 py-2 text-lg text-gray-700 p-2 pt-4"
            >
              WHY
              <span class="text-[#D02829]"> PURSUE ONLINE MBA WITH US? </span>
            </h2>
            <div class="p-2">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPYAAAAFCAYAAAB2FBF/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMi1jMDAwIDc5LjFiNjVhNzliNCwgMjAyMi8wNi8xMy0yMjowMTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkUzNjUzQjI1OUU1MTExRUU5QTMxRTM1NEMwQjFDQkIwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkUzNjUzQjI2OUU1MTExRUU5QTMxRTM1NEMwQjFDQkIwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTM2NTNCMjM5RTUxMTFFRTlBMzFFMzU0QzBCMUNCQjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTM2NTNCMjQ5RTUxMTFFRTlBMzFFMzU0QzBCMUNCQjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7QHtWEAAAASklEQVR42uzToQ0AIRBE0Rk6QdEIZdMIilIWD3LFJdx/csWKSb4lhRIiwudt1Jb6CfxJX/NqyHaqocKswHsIGyBsAIQN4BNbgAEAcYQJCaAKsE0AAAAASUVORK5CYII="
              />
            </div>
          </div>
          <div
            class="flex flex-wrap justify-center md:mt-8 mt-4 items-center gap-2"
          >
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="justify-center m-auto items-center">
                <img
                  src="../assets/odl/VO---Website-Icon-AICTE-Approved-curriculum.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">AICTE Approved curriculum</p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="justify-center m-auto items-center">
                <img
                  src="../assets/odl/VO---Website-Icon--State-of-the-art-learning-management-system-(LMS).png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">
                State-of-the-art learning management system (LMS)
              </p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="justify-center m-auto items-center">
                <img
                  src="../assets/odl/VO---Website-Icon--Live-online-weekend-classes.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">Live online weekend classes</p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="">
                <img
                  src="../assets/odl/VO---Website-Icon--Interactive-audio-video-lectures.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">Interactive audio-video lectures</p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="">
                <img
                  src="../assets/odl/VO---Website-Icon--Independent-and-group-projects.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">Independent and group projects</p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="">
                <img
                  src="../assets/odl/VO---Website-Icon--Continuous-feedback-and-analytics.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">Continuous feedback and analytics</p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="">
                <img
                  src="../assets/odl/VO---Website-Icon--Technology-enabled-online-proctored-examinations.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">
                Technology-enabled online proctored examinations
              </p>
            </div>
            <div
              class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            >
              <div class="">
                <img
                  src="../assets/odl/VO---Website-Icon--Hands-on-practical-and-lab-exercises.png"
                  alt=""
                  class="w-[40%] justify-center m-auto items-center"
                />
              </div>
              <p class="text-center p-4">
                Hands-on practical and lab exercises
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white pb-5" id="sec5">
      <div
        class="max-w-7xl mx-auto flex flex-col lg:flex-row place-items-center"
      >
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <div class="flex flex-col text-center py-4">
            <h2
              class="font-bold uppercase lg:text-2xl mt-8 px-8 py-4 text-lg text-gray-700 p-2 pt-4"
            >
              Who can
              <span class="text-[#D02829]">Apply</span>
            </h2>
            <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
          </div>
          <div class="flex flex-row">
            <div
              class="flex lg:flex-row flex-col lg:gap-8 gap-4 w-full justify-center py-4"
            >
              <div class="flex flex-col justify-center items-center">
                <img
                  src="../assets/eligility.webp"
                  alt=""
                  class="ml-2 w-[50%] md:w-full"
                />
              </div>
              <div class="flex flex-col p-4">
                <h4
                  class="font-semibold text-[#ca2526] text-left pt-4 cursor-pointer"
                >
                  Eligibility
                </h4>
                <p class="text-left">
                  Pass in an undergraduate (Bachelor) program of a minimum
                  duration of three (3) years with a minimum aggregate of 45% or
                  an equivalent letter/numerical grade in any stream from a
                  UGC-recognized university.
                </p>
              </div>

              <div class="flex flex-col">
                <div class="flex-col flex justify-center align-middle m-auto">
                  <div
                    class="bg-[#CA2526] my-4 text-white text-lg hover:text-red-500 py-3 rounded-lg shadow-xl hover:bg-white hover:border-2 hover:border-[#CA2526] justify-center items-center"
                  >
                    <a href="#enq" tabindex="-1"
                      ><p class="uppercase font-semibold">Apply Now</p></a
                    >
                  </div>
                  <div class="flex justify-center items-center gap-2">
                    <a href="tel:+918877661755">
                      <div
                        class="flex justify-center items-center bg-[#2a2a2a] text-white w-[180px] py-2 rounded-lg"
                      >
                        <img
                          src="../assets/talk-to-councellor.png"
                          class="w-8 px-2"
                          alt="call"
                        /><span class="text-[13px] leading-2 font-bold"
                          >Talk to a Councellor</span
                        >
                      </div></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#f7f9fb] pt-5 pb-1 w-full">
      <div class="w-full mx-auto max-w-7xl py-8">
        <div>
          <h2 class="text-lg md:text-2xl font-bold uppercase py-4">
            Our <span class="text-primary">Testimonials</span>
          </h2>
        </div>

        <div class="w-full relative justify-center m-auto">
          <div
            class="next-testimonials absolute bottom-0 right-32 md:top-[40%] md:-right-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-next.svg"
              class=""
              width="40px"
              height="40px"
              alt="slider-next"
            />
          </div>
          <div
            class="prev-testimonials absolute bottom-0 left-32 md:top-[40%] md:-left-9 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-prev.svg"
              width="40px"
              height="40px"
              class=""
              alt="slider prev"
            />
          </div>

          <div class="slick-testimonials flex lg:gap-10 gap-1">
            <div
              class="hover:bg-[#d02829] rounded-lg p-2 w-[70%] mx-auto text-black hover:text-white"
              @click="openmodal(video.src, video.name)"
              v-for="video in videos"
              :key="video"
            >
              <div class="flex justify-center">
                <div class="h-[325px]">
                  <div class="py-2 px-2 text-left">
                    <p class="font-semibold text-[16px]">{{ video.name }}</p>
                    <p class="font-[400]">(MBA-Online)</p>
                  </div>

                  <div class="">
                    <img
                      :alt="video.name"
                      :src="video.imgsrc"
                      width="327px"
                      height="184px"
                      class="h-[184px] w-[327px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal" id="myModal">
      <div class="modal-content1 mb-20">
        <span class="close" @click="closeModal">&times;</span>
        <iframe
          :src="selectedVideo"
          width="100%"
          height=""
          class="border-none md:h-550px h-[100%]"
        ></iframe>
        <p class="font-bold text-center pt-5 pb-2">{{ selectedVideoName }}</p>
        <p class="font-bold text-center">(MBA-Online)</p>
      </div>
    </div>
    <section class="pb-8">
      <h2 class="uppercase text-lg md:text-2xl font-bold pt-8">
        HIRING <span class="text-primary">COMPANIES</span>
      </h2>
      <HiringComp />
    </section>
    <section class="bg-[#D02829]">
      <div class="lg:w-1/2 float-left">
        <div class="flex justify-center">
          <img src="../assets/university.webp" class="lg:h-[500px]" />
        </div>
      </div>

      <div class="lg:w-1/2 bg-[#D02829] text-left float-left text-white">
        <div class="about-content lg:h-[500px]" style="padding: 20px">
          <h3 class="text-white font-semibold py-2 lg:text-2xl text-xl">
            ABOUT VIGNAN ONLINE
          </h3>
          <p class="py-1">
            With Vignan Online we have raised the bar higher, for ourselves.
            Innovation, global curriculum, world-class education, top-notch
            faculty along with a special focus on strengthening ethical values &
            developing social consciousness amongst students, has led to a
            paradigm shift in the way higher education is delivered & received.
            And we are on top of it. Our learnings of 45 years, we are now
            taking beyond the hallways of the University and offering to
            students anywhere & everywhere.
          </p>
        </div>
      </div>
    </section>

    <div class="w-full p-5 bg-[#2a2a2a]">
      <div
        class="max-w-7xl mx-auto w-full text-white font-semibold text-sm flex flex-col md:flex-row justify-center items-center gap-5"
      >
        <div class="flex flex-col justify-center items-center gap-3">
          <div class="">
            <p class="">&copy; 2023 VIGNAN Online All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
// import EnquireFormNew from "../components/EnquireFormNew.vue";
import HiringComp from "@/components/HiringComp.vue";
import StickyView from "@/components/StickyView.vue";

export default {
  name: "NewLpView",
  components: {
    // EnquireFormNew,
    HiringComp,
    StickyView,
  },
  data() {
    return {
      Electives: [],
      ElecFaqs: [],
      isModalOpen: false,
      is_widget: false,
      showMenu: false,
      openTab: 1,
      countdown: "Loading...", // Initial value before the timer starts
      selectedVideo: "",
      selectedVideoName: "",
      videos: [
        {
          name: "Sowmya",
          src: "https://www.youtube.com/embed/2kvf3TQVr8E?si=Tz-pcFilpb2cMEWH",
          imgsrc: require("@/assets/home/media/Sowmya.webp"),
        },
        {
          name: "Mohammad Altaf Hussain",
          src: "https://www.youtube.com/embed/Gm4hoVxaMQQ?si=sjjs08pVy_SE5nob",
          imgsrc: require("@/assets/home/media/Mohammed-Altaf-Hussain.webp"),
        },
        {
          name: "Rajendra",
          src: "https://www.youtube.com/embed/IXjbDHNxBBM?si=y7JcdrTJ7fP3FMSC",
          imgsrc: require("@/assets/home/media/Rajendra.webp"),
        },
        {
          name: "Vijay",
          src: "https://www.youtube.com/embed/Tpyj8WURJ4M?si=-6KHsTCJ57TqAc_-",
          imgsrc: require("@/assets/home/media/Vijay.webp"),
        },
        {
          name: "K M Naidu",
          src: "https://www.youtube.com/embed/UnADDFbRYIQ?si=k4v1CrlhjSa1EhK9",
          imgsrc: require("@/assets/home/media/K-M-Naidu.webp"),
        },
        {
          name: "Swati",
          src: "https://www.youtube.com/embed/Vf-x-1nm0nI?si=_AYhfBPMCstJOAJi",
          imgsrc: require("@/assets/home/media/Swati.webp"),
        },
        {
          name: "Massudan",
          src: "https://www.youtube.com/embed/jCfTOo2sKD8?si=kkaEVMMwG7JWXDsH",
          imgsrc: require("@/assets/home/media/Massudan.webp"),
        },
        {
          name: "Ramya",
          src: "https://www.youtube.com/embed/jc5HNE3oans?si=e8M1Lo7-OHtIgr6L",
          imgsrc: require("@/assets/home/media/Ramya.webp"),
        },
      ],
    };
  },

  created() {
    this.electiveCall();
    setTimeout(() => {
      this.is_widget = true;
    }, 2000);
  },

  mounted() {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);

    $(document).ready(function () {
      $(".slider").slick({
        centerMode: true,
        centerPadding: "10px",
        slidesToShow: 5,
        speed: 1500,
        index: 2,
        autoplay: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: "10px",
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "10px",
              slidesToShow: 1,
            },
          },
        ],
      });

      $(".slick-ranking").slick({
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: $(".prev-rank1"),
        nextArrow: $(".next-rank1"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

      $(".slick-testimonials").slick({
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: $(".prev-testimonials"),
        nextArrow: $(".next-testimonials"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  },

  methods: {
    openmodal(video, name) {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.selectedVideo = video;
      this.selectedVideoName = name;
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";
      this.selectedVideo = "";
    },
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },

    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },

    faq(id) {
      let faq_list = document.querySelectorAll(".answer-list");
      faq_list.forEach((el) => {
        if (!el.classList.contains("hidden")) {
          el.classList.add("hidden");
        }
      });
      document.getElementById(`answer-${id}`).classList.toggle("hidden");
    },

    async electiveCall() {
      const slug = this.$route.params.slug;
      const apiUrl = `${process.env.VUE_APP_API}/lp/electives/?slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.slick-center .slide-h3 {
  color: #fff;
}

.slider {
  height: 150px;
  margin: 20px auto;
  text-align: center;
}

.slider div {
  margin-right: 5px;
}

.slick-slide {
  opacity: 0.6;
  width: 10px;
}

.slick-center {
  display: block;
  max-width: 10% !important;
  max-height: 20% !important;
  opacity: 1;
}

.slick-content {
  font-size: 15px;
  color: #fff;
}

.bg-blue-900 {
  display: none; /* Initially hide all hidden divs */
}

.bg-blue-900 .active {
  display: block; /* Show the hidden div of the center slide when it's active */
}

.content12 {
  display: none; /* Initially hide all hidden divs */
}

.content12 .active {
  display: block; /* Initially hide all hidden divs */
}

.list5 li {
  list-style-image: url(https://api.vignanonline.com//media/uploads/2024/01/22/red-arrow.png);
  width: 15px;
  height: 15px;
  margin-right: 15px;
  position: absolute;
  display: inline-block;
}

.content12 {
  text-align: left;
  left: 1%;
}

@media (max-width: 767.98px) {
  .question {
    margin-bottom: 8px;
    width: 83%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .content12 {
    text-align: center;
  }

  .faq-qus-arrow {
    position: absolute;
    float: right;
    top: 10px;
    right: -30px !important;
    width: 30px;
    height: 30px;
    border: 1px solid #c9c9c9;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    margin-right: -25px;
  }
  .ban-img {
    background-image: none !important;
    width: 100%;
  }
}
.question {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.faq-qus-arrow {
  position: absolute;
  float: right;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  margin-right: -25px;
}

.faq-qus-arrow:after {
  content: "";
  border: solid #c9c9c9;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 2px);
  transition: all 0.2s ease;
}
.enquire-s {
  height: 0px;
  width: 65px;
  position: fixed;
  right: 0;
  top: 55%;
  z-index: 1000;
  transform: rotate(-90deg);
  color: black;
  background-color: #d02829;
}
.enquire-s a {
  display: block;
  background: #d02829;
  height: 30px;
  width: 140px;
  border-radius: 6px 6px 0 0;
  padding: 8px 16px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  border-bottom: solid 1px #333;
  border-left: solid 1px #333;
  border-right: solid 1px #fff;
}

.methodology-box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.banner-feature-box {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 30px;
  font-size: 14px;
}

.course-features-box {
  min-width: 22%;
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}
.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -40px;
  background-color: #d02829;
  margin-left: 10px;
}
.boxshadow {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  padding: 10px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 30px;
}
.arrowmark111 ul li {
  background-image: url(https://api.vignanonline.com//media/uploads/2023/11/10/Arrow.svg);
  color: #fff;
  border-radius: 0;
  background-position: left top 7px;
  padding-left: 24px;
  height: 10px;
  display: flex;
  font-size: 16px;
  color: #fff !important;
  margin-top: 15px;
}

.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -40px;
  background-color: #d02829;
  margin-left: 10px;
}

.ban-img {
  background-image: url(../assets/banner-bg.webp);
  width: 100%;
}

.bor-element {
  width: 250px;
  height: 50px;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 10px;
  box-shadow: -13px -25px 20px -20px rgba(0, 0, 0, 0.45),
    0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content1 {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

@media (max-width: 767.98px) {
  .modal-content1 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
